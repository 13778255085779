import React from 'react'
import Layout from '../../components/Layout'
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import '../../styles/recipeCategory/soupcategory.css'

export default function mainCourse() {
    return (
        <Layout>
            {/* For page head */}
            <Helmet>
                <title>Main Course Archives - Maker Recipe</title>
            </Helmet>

            <Container className="Category-Container">
                <Row>
                    <Col md="12" className="CategoryDish-Link">
                        <Link to="/recipe/recepi-dish">
                            <h2>Main Course Recipe 1</h2>
                        </Link>
                    </Col>
                    <Col md="12" className="CategoryDish-Image">
                        <Link to="/recipe/recepi-dish">
                            <StaticImage 
                                src="../../images/recipepage/dishphoto1.jpg" 
                                alt="Soup1"
                                width={1000}
                                height={500} />
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className="CategoryDish-Link">
                        <Link to="/recipe/recepi-dish">
                            <h2>Main Course Recipe 2</h2>
                        </Link>
                    </Col>
                    <Col md="12" className="CategoryDish-Image">
                        <Link to="/recipe/recepi-dish">
                            <StaticImage 
                                src="../../images/recipepage/dishphoto1.jpg" 
                                alt="Soup1"
                                width={1000}
                                height={500} />
                        </Link>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}
